// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../../../package.json';

export const environment = {
  brand: 'avasa',
  production: false,
  domain: 'https://testavasa.navisk.com',
  domainStatics: 'https://webapitestavasa.navisk.com',
  apidomain: 'https://webapitestavasa.navisk.com/api/',
  googlekey: null,
  youtubeList: null,
  recaptchakey: '6LcwZL4eAAAAAPmgxzup1CmpCPM45IjJ1lXye2k5',
  googleSiteVerification: '7thXmbmaTL41JjfSUh-KtDjagJzZSTBsNI5Bw8xEKkk',
  chat: {
    api: null,
    socket: null,
  },
  defaultLang: 'es',
  myMessages: true,
  allowedVideo: false,
  allowedManagerUsers: true,
  version: packageJson.version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
